import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllPlan, postSubscription, selectPlan } from "../../redux/actions/privateApiAction";

function Subscription({ShowAllPlans,selectedPlan,handleClick}) {
//   const [selectedPlan, setSelectedPlan] = useState(null);
//   const dispatch = useDispatch();
//   const { ShowAllPlans ,selectSubscription} = useSelector((state) => state.privateReducer);

//   useEffect(() => {
//     dispatch(getAllPlan());
//   }, [dispatch]);

//   console.log("selectSubscription", selectSubscription);

//   const handleClick=(id)=>{
//     setSelectedPlan(id)

// dispatch(selectPlan(id))
//   }

//   useEffect(()=>{
//     if(selectSubscription){
//     dispatch(postSubscription(selectSubscription?.id))
//     }
//   },[selectSubscription])


  return (
    <div className="p-3 md:p-5">
      <div className="text-[#1A1A1A] cursor-pointer flex items-center gap-2 text-lg md:text-xl lg:text-2xl font-normal">
        Plans & Pricing
      </div>

      <hr className="border-b-1 md:border-none mt-3 md:mt-0 font-semibold" />

      <div className="flex flex-row gap-5 mt-4">
        {/* Plan Cards */}
        {Array.isArray(ShowAllPlans) && ShowAllPlans.map((ele) => (
          <div key={ele.uuid} className="flex bg-white mb-2 rounded-xl cursor-pointer flex-col max-h-[500px] max-w-[600px]" onClick={()=>handleClick(ele?.uuid)}>
            <div className="flex flex-row text-center justify-center py-3 uppercase text-[12px] font-semibold opacity-70">
              {ele?.planType} {/* Display Plan Type */}
            </div>
            <hr className="w-[10rem] mx-auto border-t border-gray-200" />

            <div className="p-4 justify-start flex flex-col">
              <div className="uppercase flex flex-row gap-1 text-xs font-normal">
     {ele?.name}
              </div>
              <div className="text-[10px] font-semibold ">
                <span className="text-lg">${ele.price}</span>{" "} {/* Display Price */}
                <span className="opacity-60 ">{ele?.duration}</span>
              </div>
            </div>

            <div className="mt-auto pb-4">
              <div className="flex justify-center text-center">
                <input
                  type="radio"
                  id={ele?.uuid} // Unique ID for each plan
                  name="plan"
                  checked={selectedPlan === ele.uuid}
                  onChange={()=>handleClick(ele?.uuid)}
                  className="hidden"
                />
                <label
                  htmlFor={ele.uuid}
                  className={`cursor-pointer rounded-full border-2 border-gray-300 w-8 h-8 flex items-center justify-center ${
                    selectedPlan === ele.uuid ? "border-green-500 bg-green-500" : ""
                  }`}
                >
                  {selectedPlan === ele.uuid && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4 text-white"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 00-1.414-1.414L7.5 12.672 4.707 9.879a1 1 0 10-1.414 1.414l3.5 3.5a1 1 0 001.414 0l8-8z"
                        clipRule="evenodd"
                      />
                    </svg>
                  )}
                </label>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Subscription;
