import React, { useEffect, useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getPayment } from "../../redux/actions/privateApiAction";

const Payment = ({ transactionId, clientSecret }) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentData, setPaymentData] = useState();
  const { verifiedPayment } = useSelector((state) => state?.privateReducer);

  // console.log("verifiedPayment ", verifiedPayment);

  useEffect(() => {
    if (paymentData?.id) {
      dispatch(getPayment(paymentData?.id));
    }
  }, [paymentData?.id, dispatch]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      console.error("Stripe or elements not loaded");
      return;
    }

    setIsLoading(true);
    // console.log("Starting payment confirmation");

    const cardElement = elements.getElement(CardElement); // Get the PaymentElement reference

    // console.log("cardElement ", cardElement);

    const { error: confirmError, paymentIntent } =
      await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement, // Pass the element reference here
          billing_details: {
            name: "Raza",
          },
        },
      });

    if (confirmError) {
      console.error("Payment confirmation error:", confirmError.message);
      setMessage(confirmError.message);
    } else {
      // Handle paymentIntent status
      // console.log("Payment intent:", paymentIntent);
      // console.log("verifiedPayment :: ", verifiedPayment);
      dispatch(getPayment(paymentIntent?.id));
      setPaymentData(paymentIntent?.id);
      if (verifiedPayment?.isSuccess) {
        if (paymentIntent) {
          if (paymentIntent?.status === "succeeded") {
            // console.log("Payment succeeded");
            navigate("/PaymentSuccess");
          } else {
            console.warn(
              "Payment not completed, status:",
              paymentIntent?.status
            );
            setMessage("Payment not completed. Please try again.");
            navigate("/PaymentFailure");
          }
        }
      } else {
        navigate("/PaymentFailure");
      }
    }

    setIsLoading(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <div className=" mb-5 ">
        <CardElement />
      </div>
      <button disabled={isLoading || !stripe || !elements} id="submit">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
        </span>
      </button>
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
};

export default Payment;
