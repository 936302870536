import { useSelector } from "react-redux";
import SecHead from "../SectionHeader/SecHead";
import { Link } from "react-router-dom";
import CategoryPolls from "../CategoryPolls/CategoryPolls";
import moment from "moment";

function LatestNewsBottom(){
    const { latestPost } = useSelector((state) => state.publicReducer);
    const createSlug = (title) =>
        title
          .toLowerCase()
          .replace(/\s+/g, "-")
          .replace(/[^\w-]+/g, "");
      function cleanString(str) {
        return str.replace(/[^a-zA-Z0-9-_]/g, "");
      }
return(<>
 <div className="md:bg-[#FFFFFF] w-full rounded-xl shadow-sm p-3 md:p-5 ">
          
          <SecHead title={"Latest Post"} redirectLink="/latest-news" />
          <div className="">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              {Array.isArray(latestPost) &&
                latestPost.map((item) => (
                  <Link
                  to={`/latest-news/${item?.slug}`}
                    key={item.id}
                    className="flex flex-col items-start  gap-4 py-4 bg-[#ffffff]"
                  >
                    <img
                      src={item?.file}
                      alt={item?.fileId}
                      className="w-full h-[18rem] md:h-[12rem] object-cover rounded-md"
                    />
                    <div className="flex flex-col mt-2 md:mt-0 opacity-80 px-4">
                      <CategoryPolls
                        title={item?.category?.name}
                        id={item?.category?.id}
                        img={item?.category?.icon}
                      />
                      <span className=" mt-1 leading-[12px] text-[12px] open-sans-600  tracking-tight"  style={{lineHeight:1.4}}>
                        {item?.title}
                      </span>
                      <span className=" date_shown mt-4">
                        {" "}
                        {moment(item?.createdAt).format("MMMM D, YYYY")}
                      </span>
                    </div>
                  </Link>
                ))}
            </div>
          </div>
        </div>
</>)
}
export default LatestNewsBottom;