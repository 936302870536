import { FaSearch } from "react-icons/fa";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { globalSearch } from "../../redux/actions/publicAction";
import searchoptionarrow from "../../assets/icons/searchoptionarrow.svg";
import { Link, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

function SearchModel({
  title,
  message,
  handleConfirm,
  handleCloseModal,
  confirmBtn,
  cancelBtn,
  handleSelect,
  setSearchModel,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(-1); // To manage selected index in suggestions
  const [isToast, setIsToast] = useState(false);
  const { globalsearch } = useSelector((state) => state.publicReducer);

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearch(value);
    setSelectedIndex(-1); // Reset the selected index when search changes
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (search.length >= 2) {
        dispatch(globalSearch(search));
      }
    }, 300); // 300ms debounce delay
    return () => clearTimeout(timer); // Clean up on unmount or input change
  }, [search]);

  const createSlug = (title) =>
    title
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^\w-]+/g, "");

  const handleSubmit = () => {
    if (search?.length > 0) {
      localStorage.setItem("search", search);
      dispatch(globalSearch(search));
      navigate(`/search/search-result/${search}`);
      setSearchModel(false);
    } else {
      setIsToast(true);
      setTimeout(() => {
        setIsToast(false);
      }, 2000);
    }
  };

  const handleSuggest = (query) => {
    setSearch(query); // Update the search input to the clicked suggestion
    localStorage.setItem("search", query);
    dispatch(globalSearch(query));
    navigate(`/search/search-result/${query}`);
    setSearchModel(false);
  };

  // Array of trending topics
  const trendingTopics = [
    "Donald Trump",
    "Kamala Harris",
    "Election",
    "US Election",
    "Polls 2024",
  ];

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && selectedIndex >= 0) {
      // If enter is pressed, submit the search with the selected suggestion
      handleSuggest(globalsearch[selectedIndex]?.title);
    } else if (e.key === "Enter") {
      handleSubmit();
    } else if (e.key === "ArrowDown") {
      // Navigate down through suggestions
      if (selectedIndex < globalsearch.length - 1) {
        setSelectedIndex(selectedIndex + 1);
      }
    } else if (e.key === "ArrowUp") {
      // Navigate up through suggestions
      if (selectedIndex > 0) {
        setSelectedIndex(selectedIndex - 1);
      }
    }
  };

  return (
    <div
      className="absolute inset-0 top-[68px] flex bg-gray-900 bg-opacity-50 z-20 justify-center md:px-40"
      onClick={handleCloseModal}
    >
      <Toaster />
      <div
        className="bg-white my-4 md:my-0 rounded-lg md:rounded-t-none shadow-lg p-6 mx-4 md:mx-0 w-full relative h-auto overflow-auto md:h-[25rem] md:block hidden"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex flex-row gap-4 text-center">
          <div className="border-2 border-[#BBC5D5] rounded-[10px] items-center w-full overflow-auto bg-white">
            <div className="flex flex-col w-full">
              <div
                className={`flex flex-row items-center px-2 py-1 rounded-lg ${
                  search ? "bg-gray-200" : "bg-white"
                }`}
              >
                <FaSearch />
                <div className="h-[23px] w-[1px] bg-gray-300 mx-2" />
                <input
                  type="search"
                  placeholder="Search Keyword"
                  className="border-none outline-none text-[10px] bg-transparent rounded-md py-1 w-full"
                  value={search}
                  onChange={handleSearch}
                  onKeyDown={handleKeyDown} // Attach keydown handler
                />
              </div>

              {search && (
                <ul className="max-h-60 mt-1 text-start">
                  {Array.isArray(globalsearch) && globalsearch.length > 0
                    ? globalsearch.slice(0, 5).map((option, index) => (
                        <li
                          key={index}
                          className={`py-2 px-4 cursor-pointer flex items-center ${
                            selectedIndex === index
                              ? "bg-gray-200"
                              : "hover:bg-gray-200"
                          }`}
                          onClick={() => handleSuggest(option?.title)}
                          onMouseEnter={() => setSelectedIndex(index)} // Optional: Hover to highlight
                        >
                          <div className="flex items-center text-[10px]">
                            <img
                              src={searchoptionarrow}
                              alt="search-icon"
                              className="h-2 w-2 mr-2"
                            />
                            {option?.title}
                          </div>
                        </li>
                      ))
                    : search.length >= 3 && (
                        <li className="py-2 px-4 text-gray-500">
                          No results found
                        </li>
                      )}
                </ul>
              )}
            </div>
          </div>
          <div className="flex flex-col">
            <button
              onClick={handleSubmit}
              className="bg-red-600 text-white px-6 py-1 rounded-lg"
            >
              {confirmBtn}
            </button>
          </div>
        </div>
        {isToast && (
          <span className="text-red-500 font-semibold text-[10px]">
            Enter at least a character!
          </span>
        )}
        <div className="flex justify-between gap-2 py-4 items-center text-[14px]">
          <div className="">Trending Topics</div>
          <button
            onClick={handleCloseModal}
            className="text-gray-700 border-2 w-14 px-3 hover:shadow duration-300 py-1 rounded-lg transition-all active:scale-95 font-bold"
          >
            X
          </button>
        </div>
        <div className="flex flex-row gap-5 flex-wrap">
          {trendingTopics.map((topic, index) => (
            <div
              key={index}
              className="border rounded-full px-5 py-3 font-bold cursor-pointer border-[#E2E7EE] capitalize"
              onClick={() => handleSuggest(topic)} // Clicking on the topic triggers search
            >
              <div className="text-[12px] font-[450] text-gray-600">
                {topic}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default SearchModel;
