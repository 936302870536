import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getFaqList } from "../redux/actions/publicAction";

function FAQ() {
  const location = useLocation();
  const dispatch = useDispatch();

  const { getFAQRsponse } = useSelector((state) => state?.publicReducer);

  const [openIndex, setOpenIndex] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getFaqList());
  }, [dispatch]);

  const isVerified = localStorage.getItem("accessToken");
  const breadCumb = [
    { id: 1, name: isVerified ? "For You" : "News", link: "/" },
    {
      id: 2,
      name: "FAQ",
      link: `${location?.pathname}`,
    },
  ];

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="bg_home md:bg-[#F7F8FA] px-0 min-[840px]:px-40 flex flex-col min-[840px]:pb-14 md:py-0">
      {/* Breadcrumb */}
      <div className="flex flex-row items-center py-5">
        {breadCumb.map((item, index) => (
          <span
            key={item.id}
            className={`text-[10px] font-medium ${
              index === breadCumb.length - 1 ? "" : "opacity-60"
            }`}
          >
            {index > 0 && <span className="mx-1">&gt;</span>}
            <Link to={item?.link} className="breadcrumb-link">
              {item.name}
            </Link>
          </span>
        ))}
      </div>

      {/* FAQ Section */}
      <div className="md:bg-[#FFFFFF] w-full md:round-lg">
        <div className="bg-white w-full p-3 md:p-5 md:round-lg">
          <h3 className="font-sans text-[21px] leading-[20px] font-semibold tracking-normal text-[#1A1A1A] hidden md:block">
            FAQ
          </h3>
          <div className="mt-4">
            {getFAQRsponse?.data?.length > 0 ? (
              getFAQRsponse?.data?.map((faq, index) => (
                <div key={index} className="mb-1 transition-all duration-500">
                  {/* Question */}
                  <button
                    className="flex justify-between items-center w-full text-left font-medium text-[#1A1A1A] p-3 rounded-md bg-gray-100 "
                    onClick={() => toggleAccordion(index)}
                  >
                    {index + 1} {faq?.question}
                    <span className="text-sm  ">
                      {openIndex === index ? "-" : "+"}
                    </span>
                  </button>

                  {/* Answer */}
                  {openIndex === index && (
                    <div className={`p-3 text-sm text-gray-600 mt-1`}>
                      {faq?.answer}
                    </div>
                  )}
                </div>
              ))
            ) : (
              <p>No FAQs available at the moment.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FAQ;
