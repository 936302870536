import { CATEGORY_DETAIL, FOR_YOU_DATA, GET_FAQ_DATA, GET_BACK_TO_DETAILS, GET_NAVBAR_MENULIST, GET_PUBLIC_LACAL_NEWS, GET_PUBLIC_POST, GET_PUBLIC_POST_DETAILS, GLOBAL_SEARCH, LATEST_POST, MORE_POST, PICK_FOR_YOU_DATA, POST_COMMENT, STORE_COMMENT, YOUR_TOPIC_DATA } from "../actions/type";
const initialState = {
    publicPost: [],
    publicPostDetails: {},
    publicLocalNews: [],
    menuList: [],
    latestPost: [],
    morepostdata: {},
    navbarlistid: "",
    globalsearch: "",
    forYou: [],
    yourTopic: [],
    pickforyou: [],
    postcommentres: "",
    storedComment: "",
    getbacktodetails: "",
    getFAQRsponse: []
}

const publicReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PUBLIC_POST: return { ...state, publicPost: action.payload?.data }
        case GET_PUBLIC_POST_DETAILS: return { ...state, publicPostDetails: action.payload?.data }
        case GET_PUBLIC_LACAL_NEWS: return { ...state, publicLocalNews: action.payload?.data }
        case LATEST_POST: return { ...state, latestPost: action?.payload?.data }
        case MORE_POST: return { ...state, morepostdata: action?.payload?.data }
        case GET_NAVBAR_MENULIST: return { ...state, menuList: action.payload }
        case CATEGORY_DETAIL: return { ...state, navbarlistid: action?.payload?.data }
        case GLOBAL_SEARCH: return { ...state, globalsearch: action?.payload?.data }
        case FOR_YOU_DATA: return { ...state, forYou: action?.payload?.data }
        case YOUR_TOPIC_DATA: return { ...state, yourTopic: action?.payload?.data }
        case PICK_FOR_YOU_DATA: return { ...state, pickforyou: action?.payload?.data }
        case POST_COMMENT: return { ...state, postcommentres: action?.payload?.msg }
        case STORE_COMMENT: return { ...state, storedComment: action?.payload }
        case GET_BACK_TO_DETAILS: return { ...state, getbacktodetails: action?.payload }
        case GET_FAQ_DATA: return { ...state, getFAQRsponse: action?.payload }
        default: return state;
    }
}
export default publicReducer;