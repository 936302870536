// App.js
import React from 'react';
import './index.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import WithLayout from './components/Layout/Layout';
import Home from './pages/Home/Home';
import Login from './pages/Login/Login';
import Following from './pages/Following/Following';
import FollowYourInterests from "./pages/FollowYourInterests/FollowYourInterests";
import Plansprice from './pages/Plansprice/Plansprice';

import Uspolls from './pages/us-polls/Uspolls';
import PaymentSuccess from './pages/PaymentSuccess/PaymentSuccess';
import PaymentFailure from './pages/PaymentFailure/PaymentFailure';
import Myaccount from './pages/myaccount/Myaccount';
import Subscriptions from './pages/subscriptions/Subscriptions';
import Purchasehistory from './pages/purchasehistory/Purchasehistory';
import Notification from './pages/notification/Notification';
import Settings from './pages/settings/Settings';
import Termcondition from './pages/termcondition/Termcondition';
import Prediction from './pages/prediction/Prediction';
import UsePollPrediction from './pages/use-poll-prediction/UsePollPrediction';
import Polling from './pages/polling/Polling';
import { Toaster } from './components/Toaster/Toaster';
import Topstories from './pages/top-stories/Topstories';
import LocalNews from './pages/local-news/LocalNews';
import Tranding from './pages/trending/Trending';
import MorePosts from './pages/more-posts/MorePosts';
import PickForYou from './pages/picks-for-you/PickForYou';
import ForYou from './pages/for-you/ForYou';
import YourTopic from './pages/your-topics/YourTopic';
import ForYouDetails from './pages/for-you-details/ForYouDetails';
import LatestNews from './pages/latest-news/LatestNews';
import TopStorydetailsId from './pages/top-stories/TopStorydetailsId';
import TrendingDetailsId from './pages/trending/TrendingDetailsId';
import LocalNewsDetailsId from './pages/local-news/LocalNewsDetailsId';
import LatestNewsDetailsId from './pages/latest-news/LatestNewsDetailsId';
import PickForYouDetailsId from './pages/picks-for-you/PickForYouDetailsId';
import MorePostsDetailsId from './pages/more-posts/MorePostsDetailsId';
import YourTopicDetailsId from './pages/your-topics/YourTopicDetailsId';
import PollsNavDetails from './pages/us-polls/general-stories/PollsNavDetails';
import SearchResult from './pages/search-result/SearchResult';
import ContactUs from './pages/contact_us';
import AboutUs from './pages/about_us';
import RefundPolicy from './pages/refund_policy';
import FAQ from './pages/faq';
import TermCondition from './pages/term_of_service';
import PrivacyPolicy from './pages/privacy_policy';



const App = () => {
  const pages = [
    { path: '/login', component: Login },
    { path: '/', component: Home },
    { path: '/following', component: Following },
    { path: "/followyourinterests", component: FollowYourInterests },
    { path: "/plansprice", component: Plansprice },
    { path: "/top-stories/:slug", component: TopStorydetailsId },
    { path: '/trending/:slug', component: TrendingDetailsId },
    { path: '/local-news/:slug', component: LocalNewsDetailsId },
    { path: '/latest-news/:slug', component: LatestNewsDetailsId },
    { path: '/picks-for-you/:slug', component: PickForYouDetailsId },
    { path: '/more-posts/:slug', component: MorePostsDetailsId },
    { path: '/your-topics/:slug', component: YourTopicDetailsId },
    { path: '/for-you-details/:id', component: ForYouDetails },
    { path: '/:slug/general-stories', component: Uspolls },
    { path: '/:slug/polling', component: Polling },
    { path: '/PaymentSuccess', component: PaymentSuccess },
    { path: '/PaymentFailure', component: PaymentFailure },
    { path: '/myaccount', component: Myaccount },
    { path: '/subscriptions', component: Subscriptions },
    { path: '/purchasehistory', component: Purchasehistory },
    { path: '/notification', component: Notification },
    { path: '/settings', component: Settings },
    { path: '/termcondition', component: Termcondition },
    { path: '/prediction', component: Prediction },
    { path: '/use-poll-prediction/:id', component: UsePollPrediction },
    // { path: '/polling', component: Polling },
    { path: '/top-stories', component: Topstories },
    { path: '/local-news', component: LocalNews },
    { path: '/latest-news', component: LatestNews },
    { path: '/trending', component: Tranding },
    { path: '/more-posts', component: MorePosts },
    { path: '/picks-for-you', component: PickForYou },
    { path: '/for-you', component: ForYou },
    { path: '/your-topics', component: YourTopic },
    { path: '/:slug/general-stories/:titleSlug', component: PollsNavDetails },
    {path:'/search/search-result/:slug',component:SearchResult},

    // footer pages
    { path: '/about_us', component: AboutUs },
    { path: '/contact_us', component: ContactUs },
    { path: '/faq', component: FAQ },
    { path: '/privacy_policy', component: PrivacyPolicy },
    { path: '/refund_policy', component: RefundPolicy },
    { path: '/term_of_service', component: TermCondition },

  ];

 


  return (
    <>

      <Router>
        <Routes>
          {pages.map(({ path, component: Component }, index) => {
            const Layout = Component.Layout || WithLayout;
            return (
              <Route
                key={index}
                path={path}
                element={
                  <Layout>
                    <Component />
                  </Layout>
                }
              />
            );
          })}
        </Routes>
      </Router>
      <Toaster />
      {/* <Router>
     
      <Toaster />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Home />} />
        {pages.map(({ path, component: Component }, index) => {
          const Layout = Component.Layout || WithLayout;
          return (
            <Route
              key={index}
              path={path}
              element={
                <ProtectedRoute>
                  <Layout>
                    <Component />
                  </Layout>
                </ProtectedRoute>
              }
            />
          );
        })}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router> */}
    </>
  );
};

export default App;
