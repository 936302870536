import { Link } from "react-router-dom";
import PreSectionHeader from "../../components/PreSectionHeader/PreSectionHeader";
import CategoryPolls from "../../components/CategoryPolls/CategoryPolls";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { morePost } from "../../redux/actions/publicAction";
function MorePosts() {
  const dispatch = useDispatch();
  const { morepostdata } = useSelector((state) => state.publicReducer);
  const isVerified = localStorage.getItem("accessToken");
  const breadCumb = [
    { id: 1, name: isVerified ? "For You " : "News ", link: "/" },
    {
      id: 2,
      name: "More Posts",
      link: "/more-posts",
    },
  ];

  window.scrollTo(0, 0);

  useEffect(() => {
    dispatch(morePost());
  }, []);
  const createSlug = (title) =>
    title
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^\w-]+/g, "");
  function cleanString(str) {
    return str.replace(/[^a-zA-Z0-9-_]/g, "");
  }
  return (
    <>
      <div className="bg_home bg-[#F7F8FA] md:bg-[#ffffff] flex flex-col ">
        <div className="">
          <div className="md:hidden my-4 px-4">
            {" "}
            <PreSectionHeader title={"More Posts"} />
          </div>
          <hr className="border-b-1 md:border-none mt-3 md:mt-0 font-semibold" />

          {/* <div className=" flex-row px-40 gap-4 text-[12px] font-semibold py-2 bg-[#E2E7EE] hidden md:flex">
                <Link
                    to="/"
                //   className={` ${
                //     location.pathname === "/us-polls"
                //       ? "font-bold"
                //       : "text-[#7F8FA4]"
                //   }`}
                >
                    General Stories
                </Link>

            </div> */}

          {/* Web view: Display all stories */}
          <div className="bg_home md:bg-[#F7F8FA]  px-0 min-[840px]:px-40 flex flex-col min-[840px]:pb-14 md:py-0">
            <div className="flex flex-row items-center py-5">
              {breadCumb.map((item, index) => (
                <span
                  key={item.id}
                  className={`text-[10px] font-medium  ${
                    index === breadCumb.length - 1 ? "" : "opacity-60"
                  }`}
                >
                  {index > 0 && <span className="mx-1">&gt;</span>}
                  <Link to={item?.link} className="breadcrumb-link">
                    {item.name}
                  </Link>
                </span>
              ))}
            </div>
            <div className="md:bg-[#FFFFFF] w-full    md:rounded-lg">
              <div className="bg-white w-full  p-3 md:p-5  md:rounded-lg">
                {/* <SecHead title={"Top Stories"} redirectLink="/top-stories" /> */}
                <h3 className="flex font-sans text-[21px] leading-[20px] font-semibold tracking-normal text-[#1A1A1A] md:block">
                  More Posts
                </h3>
                <div className=" ">
                  {Array.isArray(morepostdata) &&
                    morepostdata.map((post) => (
                      // console.log(post, "post", post?.file?.[0]?.url),
                      <Link
                        // to={`/top-story-details/${post?.uuid}`}
                        to={`/more-posts/${post?.slug}`}
                        key={post?.id}
                        className="flex flex-col md:flex-row items-start gap-5 py-4  relative"
                      >
                        {console.log("post?.file ", post?.file)}
                        {post?.file && (
                          <img
                            src={post?.file}
                            alt={post?.title}
                            className="w-full md:w-[14rem] h-36  object-cover rounded-md"
                          />
                        )}
                        <div className="flex flex-col gap-1">
                          <CategoryPolls
                            key={post?.category?.id}
                            title={post?.category?.name}
                            postid={post?.category?.id}
                            img={post?.category?.icon}
                          />

                          {/* <span className="font-normal text-base leading-6 font-open-sans tracking-normal text-custom-dark-gray text-left">
  <!-- Content here -->
</span> */}

                          {/* <CategoryPolls title={post?.categories} */}
                          {/* <span className="text-[#1A1A1A] font-normal text-sm w-[296px] md:text-[16px] "> */}
                          {/* <span className="font-normal text-base leading-6 font-open-sans tracking-normal text-custom-dark-gray text-left"> */}
                          <span
                            className=" text-[#1A1A1A] open-sans-600  tracking-tight"
                            style={{ lineHeight: 1.4 }}
                          >
                            {post?.title}
                          </span>
                          {/* <span
                            className="text-[#7F8FA4] text-manage font-normal text-xs leading-[18px] font-open-sans tracking-normal text-custom-gray text-left"
                            style={{ lineHeight: 1.5 }}
                            dangerouslySetInnerHTML={{
                              __html: post?.description,
                            }}
                          /> */}
                          {/* <span className="text-[#7F8FA4]  text-manage font-normal text-xs leading-[18px] font-open-sans tracking-normal text-custom-gray text-left">
                    {post?.description}
                  </span> */}
                          <span className=" date_shown relative md:absolute md:bottom-3">
                            {moment(post?.createdAt).format("MMMM D, YYYY")}
                          </span>
                        </div>
                      </Link>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default MorePosts;
