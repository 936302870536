import React, { useEffect, useRef, useState } from "react";
import { Link, redirect, useLocation, useNavigate } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import { FaBars, FaSearch, FaTimes } from "react-icons/fa";
import logo from "../../assets/icons/O&R_Admin_Logo.png";
import { MdKeyboardArrowDown } from "react-icons/md";
import avtar from "../../assets/icons/avtarWhite.svg";
// import cron from "../../assets/icons/Crown_White.svg";
import ConfirmationModel from "../CustomModel/ConfirmationModel";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../redux/actions/authAction";
import {
  getCatagoryDetail,
  getNavbarMenuList,
  globalSearch,
} from "../../redux/actions/publicAction";
import { getAccountDetails } from "../../redux/actions/privateApiAction";
import SearchModel from "../CustomModel/SearchModel";

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [getLoggedIn, setGetLoggedIn] = useState();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSeachModel, setSearchModel] = useState(false);
  const [links, setLink] = useState();
  const [navmenuList, setnavMenuList] = useState({ menuList: { data: [] } });
  const [search, setSearch] = useState();
  const [selectedOption, setSelectedOption] = useState(null);
  const menuRef = useRef(null);
  const dropdownRef = useRef(null);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const isVerified = localStorage.getItem("accessToken");
  const { menuList } = useSelector((state) => state.publicReducer);
  const { getaccount } = useSelector((state) => state.privateReducer);

  const profileDropMenus = [
    {
      id: 1,
      name: "My Account",
      redirectLink: "/myaccount",
    },
    // {
    //   id: 2,
    //   name: "Prediction",
    //   redirectLink: "/prediction",
    // },
    {
      id: 3,
      name: "Following",
      redirectLink: "/following",
    },

    // {
    //   id: 4,
    //   name: "Subscriptions",
    //   redirectLink: "/subscriptions",
    // },
    // {
    //   id: 5,
    //   name: "Purchase History",
    //   redirectLink: "/purchaseHistory",
    // },
    // {
    //   id: 6,
    //   name: "Notification",
    //   redirectLink: "/notification",
    // },
    // {
    //   id: 7,
    //   name: "Settings",
    //   redirectLink: "/settings",
    // },
    // {
    //   id: 8,
    //   name: "Term & Conditions",
    //   redirectLink: "/termcondition",
    // },
    {
      id: 9,
      name: "Log out",
      redirectLink: "#",
    },
  ];

  const toggleDropdown = (id) => {
    setDropdownOpen(dropdownOpen === id ? null : id);
  };

  const handleLinkClick = () => {
    setIsDropdownOpen(false);
    setIsMobileMenuOpen(false);
  };

  const handleLogoutClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSearchModel(false);
  };

  const handleConfirmLogout = () => {
    localStorage.clear();
    // dispatch(logoutUser());
    window.location.href = "/";
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  // profile drop-down
  const toggleDropdownProfile = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
      setDropdownOpen(null);
    }
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMobileMenuOpen(false);
    }
  };

  useEffect(() => {
    handleredirct();
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // token get from local

  useEffect(() => {
    const isVerified = localStorage.getItem("accessToken");
    setGetLoggedIn(isVerified);
  }, []);

  useEffect(() => {
    dispatch(getNavbarMenuList());
  }, []);

  // links
  // console.log("navmenuList",navmenuList?.reverse().join());

  useEffect(() => {
    const menuItems = Array.isArray(menuList?.data) ? menuList?.data : [];

    // console.log("menuItems", menuItems);

    const primaryLinks = menuItems.slice(0, 3);
    const moreLinks = menuItems.slice(3);
    // console.log("primaryLinks", primaryLinks, "moreLinks", moreLinks);

    const formattedLinks = [
      { id: "", name: isVerified ? "For You" : "News", link: "/" },
      ...primaryLinks.map((item) => ({
        id: item.uuid,
        name: item.name,
        link: `/${item.slug}`,
      })),
    ];

    // console.log("formattedLinks", formattedLinks);

    if (moreLinks.length > 0) {
      formattedLinks.push({
        id: "#",
        name: "More",
        link: "#",
        children: moreLinks.map((item) => ({
          id: item.uuid,
          name: item.name,
          link: `/${item.slug}`,
        })),
      });
      // console.log("formattedLinksformattedLinks", formattedLinks);
    }
    // console.log("formattedLinksformattedLinksformattedLinks", formattedLinks);

    setLink(formattedLinks);
  }, [menuList?.data, isVerified]);

  // const handleSearch = (e) => {
  //   const value = e.target.value;
  //   setSearch(value);
  // };

  // console.log("globalsearch", globalsearch);
  // useEffect(()=>{
  //   if(param?.id !== ""){
  //     dispatch(getCatagoryDetail(param?.id))
  //   }
  //     },[param?.id])
  const handleredirct = (link) => {
    // console.log("handleredirctid", link);
    const refLink = sessionStorage.getItem("redirectLink");
    dispatch(getCatagoryDetail(link || refLink));
  };

  useEffect(() => {
    if (isVerified) {
      dispatch(getAccountDetails());
    }
  }, [dispatch, isVerified]);

  const handleSearchModel = () => {
    setSearchModel(true);
  };

  // const handleSelect = (search) => {
  //   setSelectedOption(search);
  //   // console.log("Selected:", globalsearch);
  //   // dispatch(globalSearch(search))
  //   navigate('/search/search-result/${search}');

  //   setSearchModel(false);
  //   // Call handleCloseModal if you want to close the modal after selection
  //   // handleCloseModal();
  // };

  const forYou = [
    "/top-stories",
    "/local-news",
    "/latest-news",
    "/trending",
    "/picks-for-you",
    "/more-posts",
    "/your-topics",
  ];

  return (
    <div className="">
      <nav className="bg-white shadow-md">
        <div className="flex items-center justify-between md:justify-around px-4 py-3 md:px-40">
          <div className="md:hidden flex items-center">
            <button
              onClick={toggleMobileMenu}
              className={`md:hidden flex items-center justify-center h-12 w-12 p-2 transition-transform duration-300 ease-in-out transform  focus:outline-none`}
            >
              {isMobileMenuOpen ? "" : <FaBars style={{ fontSize: "24px" }} />}
            </button>
          </div>

          <Link to={isVerified ? "/" : "/"} className=" flex me-4">
            <img src={logo} alt="logo " className="cursor-pointer " />
          </Link>

          <div
            className="flex items-center gap-2 cursor-pointer md:hidden"
            onClick={toggleDropdownProfile}
          >
            <span className="text-sm hidden md:flex font-medium text-[#1A1A1A]  flex-col break-words">
              {getaccount ? getaccount?.firstName : "user"}
            </span>
            {getLoggedIn !== null && getLoggedIn !== undefined ? (
              <div className="bg-black rounded-full w-9 h-9">
                <img
                  src={getaccount?.profilePicture || avtar}
                  alt="Profile Icon"
                  className="mx-auto p-2 w-9 h-9"
                />
              </div>
            ) : (
              <>
                {" "}
                <Link to="/login" className="text-gray-600 hover">
                  Login
                </Link>
              </>
            )}
          </div>

          <div className="hidden md:flex-[4] md:flex items-center gap-4">
            <ul className="flex flex-1 justify-center items-center gap-4">
              {/* <link to="/">{{ id: 1, name: isVerified ? "For You" : "News",}}</link> */}
              {Array.isArray(links) &&
                links.map((item, index) => (
                  // console.log("linksitem", item),
                  <li
                    key={item.id}
                    className="relative flex items-center justify-center gap-4"
                    onClick={() => toggleDropdown(item.id)}
                  >
                    {/* {console.log("location.pathname ", location)} */}
                    <Link
                      // to={`${item?.link}/general-stories`}

                      to={
                        index === 0
                          ? `${item?.link}`
                          : `${item?.link}/general-stories`
                      }
                      className={` flex items-center   text-[14px] text-nowrap  ${
                        location.pathname === `${item?.link}/general-stories`
                          ? "bg-[#E4002B] text-[#fff]  p-1 px-2 rounded-lg"
                          : location.pathname === "/" &&
                            item?.name.toLowerCase() === "for you"
                          ? "bg-[#E4002B] text-[#fff]  p-1 px-2 rounded-lg"
                          : forYou?.includes(location.pathname) &&
                            item?.name.toLowerCase() === "for you"
                          ? "bg-[#E4002B] text-[#fff]  p-1 px-2 rounded-lg"
                          : ""
                      }`}
                      // onClick={()=>{item?.name !== "More" ? handleredirct(item.id):""}}
                      onClick={() => {
                        if (item?.name !== "More") {
                          handleredirct(item?.link);
                          sessionStorage.setItem("redirectLink", item?.link);
                        }
                      }}
                    >
                      <div
                        className={`${
                          item?.name !== "More" ? "nav-link-hover" : ""
                        }`}
                      >
                        {item.name}
                      </div>
                      {item?.children && <MdKeyboardArrowDown />}
                    </Link>

                    {item?.children && dropdownOpen === item.id && (
                      <ul
                        className="absolute top-full mt-2 left-0 w-[150px] bg-white border rounded-md shadow-lg z-50"
                        ref={dropdownRef}
                      >
                        {item.children.map((child) => (
                          <li key={child.id} className="p-2 hover:bg-gray-100">
                            <Link
                              // to={`${child?.link}`}
                              to={
                                index === 0 || child?.name === "More"
                                  ? `${child?.link}`
                                  : `${child?.link}/general-stories`
                              }
                              className={`text-[14px] ${
                                location.pathname === child?.redirectLink
                                  ? "text-[#1A1A1A] font-semibold"
                                  : "text-[#7F8FA4]"
                              }`}
                              // onClick={()=>handleredirct(child.id)}
                              onClick={() => {
                                if (child?.name !== "More") {
                                  // console.log("child",child);

                                  handleredirct(child?.link);
                                  sessionStorage.setItem(
                                    "redirectLink",
                                    child?.link
                                  );
                                }
                              }}
                            >
                              {child?.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              <div
                className="bg-gray-100 rounded-[10px] flex items-center gap-2 px-4 py-2"
                onClick={handleSearchModel}
              >
                <FaSearch />
                <div className="h-[23px] w-[1px] bg-gray-300" />
                <button className="text-[14px] opacity-70">
                  Search Keyword
                </button>
                {/* <input
                  type="search"
                  placeholder="Search Keyword"
                  className="border-none outline-none text-xs bg-transparent rounded-md py-1"
                  value={search}
                  // onChange={handleSearch}
                  onClick={handleSearchModel}
                /> */}
              </div>
            </ul>
            {isVerified !== null && isVerified !== undefined ? (
              <div className="relative ">
                <div
                  className="flex items-center gap-2 cursor-pointer"
                  onClick={toggleDropdownProfile}
                >
                  <span className="text-sm font-medium text-[#1A1A1A] flex flex-col break-words">
                    {getaccount ? getaccount?.firstName : "user"}
                  </span>
                  <div className="bg-black rounded-full w-9 h-9">
                    <img
                      src={getaccount?.profilePicture || avtar}
                      alt="Profile Icon"
                      className={`mx-auto  w-9 h-9  ${
                        getaccount?.profilePicture
                          ? "overflow-hidden rounded-full object-cover "
                          : "p-2"
                      }`}
                    />
                  </div>
                </div>

                {isDropdownOpen && (
                  <div
                    className="absolute right-0 mt-2 w-48 bg-white text-[#1A1A1A] rounded-lg shadow-xl border-2 z-50"
                    ref={dropdownRef}
                  >
                    <div className="my-2">
                      {profileDropMenus.map((ele, index) => (
                        // console.log("location.pathname === ele?.redirectLink ",location.pathname ,ele?.redirectLink,location.pathname === ele?.redirectLink ),

                        <ul key={ele?.id}>
                          <Link
                            to={ele?.redirectLink}
                            className={` ${
                              location.pathname === ele?.redirectLink
                                ? "text-[#1A1A1A] font-semibold"
                                : ""
                            }`}
                            // to={
                            //   index === 0
                            //     ? `${ele?.link}`
                            //     : `${ele?.link}/general-stories`
                            // }
                            // className={`   ${
                            //   location.pathname === `${ele?.link}/general-stories`
                            //     ? "text-[#1A1A1A] font-semibold"
                            //     : ""
                            // }`}
                          >
                            <li
                              className="px-4 py-1 hover:bg-gray-100 text-[12px] cursor-pointer"
                              onClick={
                                ele.name === "Log out"
                                  ? handleLogoutClick
                                  : handleLinkClick
                              }
                            >
                              {ele?.name}
                            </li>
                          </Link>
                        </ul>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="flex items-center cursor-pointer gap-2">
                <h4 className="text-gray-900 hover:text-gray-900 text-[12px] font-semibold ">
                  <Link to="/login" className="text-gray-600 hover">
                    Login / Register
                  </Link>
                </h4>
                <IoIosArrowForward />
              </div>
            )}
          </div>
        </div>

        {/* for mobile  */}
        {isMobileMenuOpen && (
          <div
            className={`md:hidden fixed top-0 left-0 h-full w-full z-20 transition-transform duration-500 ease-in-out transform ${
              isMobileMenuOpen ? "translate-x-0" : "-translate-x-full"
            }`}
          >
            {/* Blurred Background */}
            <div
              className={`fixed inset-0 bg-black bg-opacity-50 transition-opacity duration-500 ${
                isMobileMenuOpen ? "opacity-100 backdrop-blur-md" : "opacity-0"
              }`}
              onClick={toggleMobileMenu}
            ></div>

            {/* Mobile Menu */}
            <div
              ref={menuRef}
              className={`fixed top-0 pt-2 md:pl-2  md:pt-0 left-0 h-full bg-[#fff] rounded-r-lg transition-transform duration-500 ease-in-out transform ${
                isMobileMenuOpen ? "translate-x-0" : "-translate-x-full"
              } w-[75vw] max-w-[75vw] z-30`}
            >
              <ul className={`flex flex-col items-start   py-2 `}>
                {links.map((item, index) => {
                  // console.log("itemitemlinks",item);

                  if (item.name === "More") {
                    return (
                      <React.Fragment key={item.id}>
                        <ul className="pl-4 " ref={dropdownRef}>
                          {item.children.map((child, index) => (
                            <Link
                              to={`${child?.link}/general-stories`}
                              // className={`   ${
                              //   location.pathname === `${ele?.link}/general-stories`
                              //     ? "text-[#1A1A1A] font-semibold"
                              //     : ""
                              // }`}
                              // to={`${child?.link}`}
                              className={` hover:bg-gray-100  text-[17px] md:text-[12px] font-normal`}
                              onClick={toggleMobileMenu}
                            >
                              <li
                                key={`${child.id}`}
                                className={`py-1 ${
                                  location.pathname === child.link
                                    ? "text-[#1A1A1A] font-semibold"
                                    : ""
                                }`}
                                onClick={() => {
                                  if (child?.name !== "More") {
                                    handleredirct(child?.link);
                                    sessionStorage.setItem(
                                      "redirectLink",
                                      child?.link
                                    );
                                  }
                                }}
                              >
                                <span className="">{child?.name}</span>
                              </li>
                            </Link>
                          ))}
                        </ul>
                      </React.Fragment>
                    );
                  }
                  return (
                    <Link
                      to={
                        index === 0
                          ? `${item?.link}`
                          : `${item?.link}/general-stories`
                      }
                      // to={`${item?.link}`}
                      className={`px-4 hover:bg-gray-100 text-[17px] md:text-[12px] font-normal `}
                      onClick={toggleMobileMenu}
                    >
                      <li
                        key={item.id}
                        className={`py-1 ${
                          location.pathname === item?.link
                            ? "text-[#1A1A1A] font-semibold"
                            : ""
                        }`}
                        onClick={() => {
                          if (item?.name !== "More") {
                            handleredirct(item?.link);
                            sessionStorage.setItem("redirectLink", item?.link);
                          }
                        }}
                      >
                        {item.name}
                      </li>
                    </Link>
                  );
                })}

                <li className="hidden">
                  <input
                    type="search"
                    placeholder="Search"
                    className="border rounded-md py-1 px-3 w-full"
                  />
                </li>
              </ul>

              <hr className="text-[#E2E7EE] bg-[#E2E7EE] mx-4" />

              <div className="">
                {profileDropMenus.map((ele) => (
                  <ul
                    key={ele?.id}
                    className="hover:bg-gray-200 cursor-pointer"
                  >
                    <li
                      className={`px-4 py-1 hover:bg-gray-200 text-[17px] md:text-[12px]  font-normal  `}
                      onClick={
                        ele.name === "Log out"
                          ? handleLogoutClick
                          : handleLinkClick
                      }
                    >
                      <Link
                        to={`${ele?.redirectLink}`}
                        // onClick={handleLinkClick}
                        className={`${
                          location.pathname === ele?.redirectLink
                            ? "text-[#1A1A1A] font-semibold"
                            : ""
                        }`}
                      >
                        {ele?.name}
                      </Link>
                    </li>
                  </ul>
                ))}
              </div>
            </div>
          </div>
        )}
      </nav>
      {/* seacrh model */}
      {isSeachModel && (
        <SearchModel
          title="Are you sure?"
          message="Do you really want to exit this."
          handleConfirm=""
          handleCloseModal={handleCloseModal}
          confirmBtn="Search"
          selectedOption={selectedOption}
          setSearchModel={setSearchModel}
        />
      )}

      {/*confirmation model */}

      {isModalOpen && (
        <ConfirmationModel
          title="Are you sure?"
          message="Do you really want to exit this."
          handleConfirm={handleConfirmLogout}
          handleCloseModal={handleCloseModal}
          confirmBtn="Leave"
          cancelBtn="Stay"
        />
      )}
    </div>
  );
};

export default Navbar;
