import { useEffect, useState } from "react";
import WithLayout from "../../components/Layout/Layout";
import Payment from "../../components/Payment/Payment";
import Subscription from "../../components/Subscription/Subscription";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllPlan,
  postSubscription,
  selectPlan,
} from "../../redux/actions/privateApiAction";

const Plansprice = () => {
  const dispatch = useDispatch();
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);

  // Load Stripe with the public key
  //   const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
  const stripePromise = loadStripe(
    "pk_test_51Q2Sd3DvnwXVb6R1HdGAkEx3Syye7XhioqtH1nmFv3sldqqgd8XUd2HJ6PE0L1cgmM8GN4dTMwd9FV6DnEyjYWgt00JfBp2QDR"
  );
  const { ShowAllPlans, selectSubscription, subscriptiondata, paymentIntent } =
    useSelector((state) => state.privateReducer);

  useEffect(() => {
    dispatch(getAllPlan());
  }, [dispatch]);

  const handleClick = (id) => {
    setSelectedPlan(id);
    dispatch(selectPlan(id));
  };

  // console.log("selectSubscription ", selectSubscription);

  useEffect(() => {
    if (selectSubscription?.id) {
      dispatch(postSubscription(selectSubscription.id));
    }
  }, [selectSubscription?.id, dispatch]);

  useEffect(() => {
    if (paymentIntent) {
      setClientSecret(paymentIntent);
    }
  }, [paymentIntent]);

  useEffect(() => {
    if (subscriptiondata?.client_secret) {
      setClientSecret(subscriptiondata?.client_secret);
    }
  }, [subscriptiondata?.client_secret]);

  const options = {
    clientSecret,
    appearance: { theme: "stripe" },
  };

  return (
    <div className="bg_home bg-[#F7F8FA] px-4 md:px-40 flex flex-col md:pt-8 pb-14">
      <div className="flex flex-col w-full md:flex-row gap-5 md:py-[10px] ">
        <div className="w-full md:w-[60%]">
          <Subscription
            handleClick={handleClick}
            selectedPlan={selectedPlan}
            ShowAllPlans={ShowAllPlans}
          />
        </div>
        <div className="w-full md:w-[40%] h-auto">
          {clientSecret && (
            <Elements stripe={stripePromise} options={options}>
              <Payment
                transactionId={subscriptiondata?.transactionId}
                clientSecret={clientSecret}
              />
            </Elements>
          )}
        </div>
      </div>
    </div>
  );
};

Plansprice.Layout = WithLayout;
export default Plansprice;
