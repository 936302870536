import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import { GoogleOAuthProvider } from '@react-oauth/google';
// import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";

// import { BrowserRouter as Router } from 'react-router-dom'



const root = ReactDOM.createRoot(document.getElementById('root'));
const clientId="518416783060-ciermau0b7el6o1jddvoqvlghfvlkvkp.apps.googleusercontent.com"
// const clientId="1093196973989-1sll1mtd3frpcjn5p4v1sml8n31tbp1c.apps.googleusercontent.com"
// const clientId="480330190515-5g8oiep48nfhelu3vkp4qv4gtuc7k93k.apps.googleusercontent.com"

root.render(
    <GoogleOAuthProvider clientId={clientId}>
  <Provider store={store}>
 
    {/* <Router> */}
      <App />
    {/* </Router> */}
  
    </Provider>
    </GoogleOAuthProvider>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
