import { Link, useLocation } from "react-router-dom";

function AboutUs() {
  const location = useLocation();
  window.scrollTo(0, 0);

  // console.log("location",location.pathname);

  const isVerified = localStorage.getItem("accessToken");
  const breadCumb = [
    { id: 1, name: isVerified ? "For You " : "News ", link: "/" },
    {
      id: 2,
      name: "About Us",
      link: `${location?.pathname}`,
    },
  ];
  return (
    <>
      <div className="bg_home md:bg-[#F7F8FA]  px-0 min-[840px]:px-40 flex flex-col min-[840px]:pb-14 md:py-0">
        <div className="flex flex-row items-center py-5">
          {breadCumb.map((item, index) => (
            // console.log("item",item),

            <span
              key={item.id}
              className={`text-[10px] font-medium  ${
                index === breadCumb.length - 1 ? "" : "opacity-60"
              }`}
            >
              {index > 0 && <span className="mx-1">&gt;</span>}
              <Link to={item?.link} className="breadcrumb-link">
                {item.name}
              </Link>
            </span>
          ))}
        </div>
        <div className="md:bg-[#FFFFFF] w-full  md:round-lg ">
          <div className="bg-white w-full  p-3 md:p-5 md:round-lg min-h-[10rem] font-sans">
            {/* <SecHead title={"Top Stories"} redirectLink="/top-stories" /> */}
            <h3 className="  text-[21px] leading-[20px] font-semibold tracking-normal text-[#1A1A1A] hidden md:block mb-3">
              About Us
            </h3>
            <div className=" mt-2">
              <p>
                Opinions & Rating is a Global Tech Based decisions platform that
                delivers Unbiased, High-Quality data and insights. Our insights
                are based upon the data enabling higher quality result.{" "}
              </p>
              <div className="text-[21px] font-semibold my-3">
                Should you Trust us?
              </div>
              <p>
                There are two elements in our approach to making decisions easy
              </p>
              <ol className="px-5 my-2">
                <li className="list-disc ml-5">
                  {" "}
                  Operating with a Framework that is standardized and repeatedly
                  tested and upgraded for accuracy
                </li>
                <li className="list-disc ml-5">
                  Hiring Talent that understands our neutral role
                </li>
              </ol>
              <p>
                While standardization is fairly common across many platforms, we
                are building towards standardization that delivers accurate
                outcomes. Accuracy is not just about coming close to the
                predicted outcomes. Accuracy is also about understanding how
                close a prediction can come to the actual outcome. Our approach
                is to hunt down fundamentals, and they are not just about the
                fundamentals of the company or the economy. They are much deeper
                for example how the number of children per household is falling
                at a rapid rate globally, and how platforms like Instagram and
                YouTube are driving a different consumptive culture than most
                can even imagine. As a team, we are adept at numbers and
                understanding causality. Causal frameworks are not easy to build
                and they need repeated testing and that is what we are doing on
                a daily basis. You will see more and more transparency in our
                forecasts and methodologies, and accuracy.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AboutUs;
