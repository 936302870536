import { FaTimes } from "react-icons/fa";
import warningicon from "../../assets/icons/Path 23141.svg";
function ConfirmationModel({
  title,
  message,
  handleConfirm,
  handleCloseModal,
  confirmBtn,
  cancelBtn
}) {
  return (
    <>
     <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 mx-4 md:mx-0 md:w-[25rem] relative ">
        {/* Close Icon */}
        <button
          onClick={handleCloseModal}
          className="absolute top-2 right-2 text-gray-400 hover:text-gray-600"
        >
          <FaTimes className="text-sm font-normal" />
        </button>

          <div className="flex flex-row gap-4">
            <div className="bg-gray-100 rounded-[10px] flex items-center gap-2 px-4 py-4">
              <img src={warningicon} alt="warning" className="h-6 w-6" />
            </div>
            <div className="flex flex-col">
              <p className="text-md text-black font-semibold ">{title}</p>

              <p className="text-sm">{message}</p>
            </div>
          </div>
          <div className="flex justify-end gap-2 mt-4">

       {confirmBtn && <button
              onClick={handleConfirm}
              className="bg-gray-300 text-black px-6 py-1 rounded-3xl"
            >
            {confirmBtn}
            </button>}

           {cancelBtn && <button
              onClick={handleCloseModal}
              className="bg-[#FF3D00] text-white px-6 py-1 rounded-3xl"
            >
              {cancelBtn}
            </button>}
          </div>
        </div>
      </div>
    </>
  );
}
export default ConfirmationModel;
