import React, { useEffect } from "react";
import SecHead from "../SectionHeader/SecHead";
import CategoryPolls from "../CategoryPolls/CategoryPolls";
import { useDispatch, useSelector } from "react-redux";
import { getyourTopic } from "../../redux/actions/publicAction";
import { Link } from "react-router-dom";
import moment from "moment";

const YourTopicsHomeComp = () => {
  const dispatch = useDispatch();
  const { yourTopic } = useSelector((state) => state.publicReducer);

  useEffect(() => {
    dispatch(getyourTopic(6));
  }, []);
  // console.log("yourTopic",yourTopic);
  const isVerified = localStorage.getItem("accessToken");
  const createSlug = (title) =>
    title
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^\w-]+/g, "");
  function cleanString(str) {
    return str.replace(/[^a-zA-Z0-9-_]/g, "");
  }

  return (
    <div className="w-full">
      <div className="flex w-full flex-col md:flex-row items-start mb-1 justify-between py-4">
        <div>
          <SecHead
            title={"Your Topics"}
            redirectLink="/your-topics"
            fontSize={2}
          />
          <p className="recme_shown">Recommended based on your interests</p>
        </div>
      </div>
      <div className="flex md:grid md:grid-cols-3 gap-5 overflow-x-auto md:overflow-x-hidden scrollbar-hide">
        {Array.isArray(yourTopic) &&
          yourTopic.map((topic) => (
            //  console.log("topic",topic),

            <div
              key={topic.id}
              className="flex-shrink-0 bg-white rounded-xl shadow-sm p-3 md:p-5 w-80 md:w-auto px-4 py-4"
            >
              <SecHead
                title={topic?.category?.name}
                id={topic?.category?.id}
                csschanges="true"
              />
              <div className="max-h-auto md:max-h-[500px] scrollbar-hide ">
                {Array.isArray(yourTopic) &&
                  yourTopic.slice(0, 3).map((item, index) => (
                    // console.log("yourTopic.length",yourTopic.length-3),

                    <Link
                      // to={`/your-topics/${item?.slug}`}
                      to={isVerified ? `/your-topics/${item?.slug}` : "/login"}

                      // to={`/your-topic-details/${item?.uuid}`}
                    >
                      <div
                        key={item.id}
                        className={`flex flex-row items-start justify-between  gap-5 py-2 border-b ${
                          // index <= yourTopic?.length - 2
                          index > 1 ? "border-none" : "border-b"
                        }`}
                      >
                        {/* {console.log("yourTopic ", yourTopic.length - 2, index)} */}
                        <div className="flex flex-col ">
                          <span className="opacity-95">
                            {" "}
                            <CategoryPolls
                              title={item?.category?.name}
                              id={item?.category?.id}
                              img={item?.category?.icon}
                            />
                          </span>
                          <span
                            className="text-gray-800 my-3 text-[16px] font-semibold tracking-normal overflow-hidden min-h-[4rem]"
                            style={{ lineHeight: 1.4 }}
                          >
                            {item?.title}
                          </span>
                          <span className="date_shown">
                            {moment(item[0]?.createdAt).format("MMMM D, YYYY")}
                          </span>
                        </div>
                        <img
                          src={item?.file}
                          alt={item?.fileId}
                          className="w-20 md:w-16 h-16 object-cover rounded-xl mt-2"
                        />
                      </div>
                    </Link>
                  ))}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default YourTopicsHomeComp;
