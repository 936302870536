import CategoryPolls from "../../CategoryPolls/CategoryPolls";
import SecHead from "../../SectionHeader/SecHead";
import shareicon from "../../../assets/icons/Path 23100.svg";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  filterpost,
  getCatagoryDetail,
  getDetailsId,
  getNavbarMenuList,
  getPostDetails,
  postComment,
  storeComment,
} from "../../../redux/actions/publicAction";
import commentedUser from "../../../assets/icons/Path 23147.svg";
import commentpostsuccess from "../../../assets/response-icon/commentpostsuccess.svg";
import somethingwentwrong from "../../../assets/response-icon/somethingwentwrong.svg";
import ResponseModel from "../../CustomModel/ResponseModel";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

function TopStoriesDetailsCom({ postDetailsid }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigator = useNavigate();
  // console.log("postDetailsid", postDetailsid);
  const [inputData, setInputData] = useState({ comment: "" });
  const [commentResponseModel, setCommentResponseModel] = useState(false);
  const [responsemsg, setResponsemsg] = useState({});
  const [secBreadCumb, setSecBreadCumb] = useState();

  const { publicPostDetails, postcommentres, storedComment, getbacktodetails } =
    useSelector((state) => state.publicReducer);
  // const { showToaster } = useSelector((state) => state.toasterData);
  const isVerified = localStorage.getItem("accessToken");

  // console.log("location?.pathname",location?.pathname);

  useEffect(() => {
    if (getbacktodetails === location?.pathname) {
      // console.log("getbacktodetails === location?.pathname",getbacktodetails,location?.pathname);

      if (storedComment) {
        setInputData({
          ...inputData,
          id: storeComment?.id,
          comment: storedComment?.comment,
        });
      }
    } else {
      setInputData({ comment: "" });
    }
  }, [storedComment, getbacktodetails, location]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputData({
      ...inputData,
      [name]: value,
    });
  };

  const handleCommentSubmit = async (id) => {
    if (isVerified) {
      if (id) {
        await dispatch(postComment({ id: id, comment: inputData?.comment }));
        setInputData({ comment: "" });
        setCommentResponseModel(true);
        dispatch(getPostDetails(postDetailsid));
      }
    } else {
      dispatch(getDetailsId(location?.pathname));
      // console.log("location?.pathname",location?.pathname);

      dispatch(
        storeComment({
          id: id,
          comment: inputData?.comment,
        })
      );
      localStorage.setItem("setBack", true);
      navigator("/login");
    }
  };

  useEffect(() => {
    if (postDetailsid) {
      // console.log("postDetailsid",postDetailsid);

      dispatch(getPostDetails(postDetailsid));
    }
  }, [postDetailsid, dispatch]);

  useEffect(() => {
    if (postcommentres) {
      setResponsemsg({ resIcon: commentpostsuccess, resMsg: postcommentres });
    } else {
      setResponsemsg({
        resIcon: somethingwentwrong,
        resMsg: "Something went wrong!",
      });
    }

    if (commentResponseModel) {
      const timeoutId = setTimeout(() => {
        setCommentResponseModel(false);
      }, 500);
      return () => clearTimeout(timeoutId);
    }
  }, [postcommentres, commentResponseModel]);

  return (
    <>
      {/* <p className="">{isVerified ? "For You" : "News"} / {publicPostDetails?.category?.name} / {publicPostDetails?.title}</p> */}

      <div className="bg-white w-full rounded-xl shadow-sm p-3 md:p-5 ">
        <div className="max-h-auto ">
          <div className="flex flex-col gap-1">
            {/* {Array.isArray(publicPostDetails) && publicPostDetails.map((ele)=>( */}
            <>
              <div className="">
                <div className="flex flex-row justify-between">
                  <div className="w-3/4 flex flex-col gap-2">
                    {" "}
                    <CategoryPolls
                      title={publicPostDetails?.category?.name}
                      id={publicPostDetails?.category?.id}
                      img={publicPostDetails?.category?.icon}
                    />
                    {/* {console.log(
                      "publicPostDetails?.category?.icon ",
                      publicPostDetails?.category?.icon
                    )} */}
                    <span
                      className=" text-[28px] leading-tight font-[500]  tracking-tight"
                      style={{ lineHeight: 1.4 }}
                    >
                      {publicPostDetails?.title}
                    </span>
                    <span className="mt-1 date_shown text-[14px]">
                      {" "}
                      {moment(publicPostDetails?.createdAt).format(
                        "MMMM D, YYYY"
                      )}
                    </span>
                  </div>
                  <div className=" flex h-8 w-8">
                    <img
                      src={shareicon}
                      alt="share"
                      className="border rounded-md p-2"
                    />
                  </div>
                </div>
              </div>

              <img
                src={publicPostDetails?.file}
                alt="details-image"
                className="w-full  object-cover rounded-md my-4"
              />

              <div
                className="text-[14px] black-heading overflow-auto"
                dangerouslySetInnerHTML={{
                  __html: publicPostDetails?.description,
                }}
              >
                {/* <p>12{publicPostDetails?.description}</p> */}
              </div>
            </>
          </div>
        </div>
      </div>

      <div className="bg-[#FFFFFF] w-full rounded-xl shadow-sm p-3 md:p-5 ">
        {/* <span className="text-[#1A1A1A] font-normal text-sm md:text-[20px]"> */}
        <span className="flex font-sans text-[21px] leading-[20px] font-semibold tracking-normal text-[#1A1A1A]">
          Leave a Reply
        </span>
        <div className="my-2">
          <div className="my-2  w-full">
            <textarea
              className="max-h-auto bg-[#F7F8FA] p-4 rounded-lg text-[10px] h-[10rem]  w-full resize-none"
              placeholder="Write a comment..."
              name="comment"
              value={inputData?.comment ?? ""}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="my-3">
          <div className="flex flex-col items-end justify-end">
            <button
              className={`rounded-full   py-2 text-[14px] font-semibold px-14 bg-[#FF3D00] text-white`}
              onClick={() => handleCommentSubmit(publicPostDetails?.id)}
            >
              <span className="opacity-80">Post Comments</span>
            </button>
          </div>
        </div>
        {/* show all comments */}
        {/* {publicPostDetails?.comments && */}
        <div className="mt-6">
          <SecHead
            title={`All Comments (${publicPostDetails?.comments?.length || 0})`}
          />
          <div className=" mt-2 border border-gray-100 px-4 rounded-md">
            {Array.isArray(publicPostDetails?.comments) &&
              publicPostDetails?.comments.map((item) => (
                <div
                  key={item.id}
                  className="flex flex-row items-start md:items-center gap-4 md:gap-4 py-2  border-b border-gray-300 last:border-none  "
                >
                  <img
                    src={commentedUser}
                    alt="alt"
                    className="  w-5 mt-2 md:w-9 h-auto object-cover rounded-lg bg-[#7F8FA4] p-3 "
                  />
                  <div className="flex flex-col mt-2 md:mt-0 md:pt-1">
                    <span className="font-[12px] text-xs  ">
                      {item?.creator?.firstName ?? "User"}
                      <span className="text-[#7F8FA4] text-xs">
                        {" "}
                        {moment(item?.createdAt).fromNow()}
                      </span>
                    </span>

                    <span className="date_shown mt-1 ">{item?.text ?? ""}</span>
                  </div>
                </div>
              ))}
          </div>
        </div>
        {/* } */}
      </div>

      {/* responsceModel */}
      {commentResponseModel && (
        <ResponseModel
          iconRes={responsemsg?.resIcon}
          msgRes={responsemsg?.resMsg}
        />
      )}
    </>
  );
}
export default TopStoriesDetailsCom;
