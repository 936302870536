import React from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";

const WithLayout = ({ children }) => (
  <div className="default-layout bg-[#F7F8FA]">
    <div className="sticky top-0 bg-white w-full z-10">
      <Navbar />
    </div>
    <main className="min-h-[80vh]">{children}</main>
    <div className="">
      <Footer />
    </div>
  </div>
);

export default WithLayout;
