import { GET_LOGIN, GOOGLE_LOGIN, LOGOUT_USER, OTP_VERIFIED, RESEND_OTP_VERIFIED } from "../actions/type";



const initialState = {
  loading: false,
  data: null,
  error: null,
  otpMessage: null,
  otpSentStatus: false,
  loginDataToken: null,
  googleloginDataToken: null,
  isAuthenticated: false,
  user: null,
  token: null,
  resendOtp: "",
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {

    case GET_LOGIN:
      // console.log("action.payload", action.payload);
      return { ...state, loading: false, otpSentStatus: action.payload.status };


    case OTP_VERIFIED:
      // console.log("OTP_VERIFIED", action.payload.data);

      return { ...state, isAuthenticated: true, loginDataToken: action.payload.data || 123456 };

    case LOGOUT_USER:
      return { ...state, isAuthenticated: false, user: null, loginDataToken: null, };

    case RESEND_OTP_VERIFIED: return { ...state, resendOtp: action?.payload?.msg }
    case GOOGLE_LOGIN: return { ...state, isAuthenticated: true, googleloginDataToken: action.payload.data }
    default:
      return state;
  }
};

export default authReducer;
