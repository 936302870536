// import api from "../api";
// import { GET_PUBLIC_POST_DETAILS, GET_PUBLIC_POST,GET_NAVBAR_MENULIST,GET_PUBLIC_LACAL_NEWS,POST_COMMENT,LATEST_POST,MORE_POST, CATEGORY_DETAIL, GLOBAL_SEARCH, FOR_YOU_DATA, YOUR_TOPIC_DATA, PICK_FOR_YOU_DATA, SHOW_TOSTER, STORE_COMMENT, GET_BACK_TO_DETAILS } from "./type";

import api from "../api";
import { POLLING_TABLE_LIST, POLLS_AVERAGE_DATA } from "./type";

export const getPollingTableList = (payload, candidateName) => async (dispatch) => {
  try {
    const response = await api.get(`/user/polls/view?${payload}&candidateName=${candidateName}`);
    if (response?.status === 200) {
      dispatch({ type: POLLING_TABLE_LIST, payload: response?.data });
    } else {
    }
  } catch (error) { }
};
export const getPollsAverage = () => async (dispatch) => {
  try {
    const response = await api.get(`/user/polls/calculate-poll-average`);
    if (response?.status === 200) {
      dispatch({ type: POLLS_AVERAGE_DATA, payload: response?.data });
    } else {
    }
  } catch (error) { }
};
