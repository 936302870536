import LoginLayout from "../../components/LoginLayout/LoginLayout";
import failPayment from "../../assets/icons/Group 26647.svg";
import { Link } from "react-router-dom";

function PaymentFailure() {
  // let paymentInfo;

  // // Try to retrieve and parse paymentInfo
  // try {
  //   const paymentInfoString = localStorage.getItem("paymentInfo");
  //   paymentInfo = paymentInfoString ? JSON.parse(paymentInfoString) : null;
  // } catch (error) {
  //   console.error("Error parsing paymentInfo from localStorage:", error);
  //   paymentInfo = null; // Fallback to null if parsing fails
  // }

  return (
    <div className="flex flex-col items-center justify-center h-screen p-4 bg-gray-50">
      <div className="w-full max-w-xl p-4">
        <div className="text-center mb-6">
          <img src={failPayment} alt="Payment Failed" className="mx-auto w-20 h-20" />
        </div>
        <div className="text-center text-2xl font-semibold text-[#1A1A1A] mb-4">
          Payment Failed!
        </div>
        <div className="text-center text-gray-600 text-xs mb-4">
          Don't worry, your money is safe! If money was debited from your account, it will be refunded automatically in 5-7 working days.
        </div>
        <div className="text-center text-gray-600 text-xs mb-6">
          {/* {paymentInfo ? (
            <>
              <div>Your Order ID: {paymentInfo[0] || 'N/A'}</div>
              <div>Your Transaction ID: {paymentInfo[1] || 'N/A'}</div>
              <div>Date & Time: July 30, 2024 at 10:50 AM</div>
            </>
          ) : (
            <div>No payment information available.</div>
          )} */}
        </div>

        <div className="flex justify-center pt-12">
          <Link to="/plansprice"
            className="rounded-lg px-24 py-2 text-[14px] font-semibold bg-[#FF3D00] text-white hover:bg-[#e63600] transition duration-200"
          >
            Retry Payment
          </Link>
        </div>
      </div>
    </div>
  );
}

PaymentFailure.Layout = LoginLayout;
export default PaymentFailure;
