import React from "react";
import indiaflag from "../../assets/flag (1).png";
import usflag from "../../assets/flag (2).png";
import canadaflag from "../../assets/flag (3).png";

const CategoryPolls = ({ id, img, title, bgtranparent }) => {
  // console.log("titletitle", img, "title", title);

  return (
    <div
      className={`flex gap-1  items-start  md:px-0 ${
        bgtranparent ? "bg-transparent" : " bg-[#FFFFFF]"
      } rounded-md`}
    >
      <img
        src={img || ""}
        // src={
        //   title === "India Polls"
        //     ? indiaflag
        //     : title === "Canada pollos"
        //     ? canadaflag
        //     : usflag
        // }
        alt=""
        className="h-[14px] w-[14px] object-cover"
      />
      <span
        className={`${
          bgtranparent ? "text-[#fff] " : "text-[#1A1A1A]"
        } md:font-semibold text-[12px] md:text-xs leading-[18px] font-normal md:font-open-sans tracking-normal text-custom-gray text-left capitalize`}
      >
        {title}
      </span>
    </div>
  );
};

export default CategoryPolls;
