import React from "react";
import successfullyPayment from "../../assets/icons/Group 25325.png";

const PaymentSuccess = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen p-4">
      <div className="w-full max-w-sm p-4">
        <div className="text-center mb-4">
          <img src={successfullyPayment} alt="Payment Successful" className="mx-auto" />
        </div>
        <div className="text-center text-2xl text-[#1A1A1A] mb-2">
          Your Payment was successful!
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
