import { useState } from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import leftPredictionPerson from "../../assets/images/Kamala harris pic.png";
import rightPredictionPerson from "../../assets/images/Donald trump pic.png";

function ElectionSeatPredictionComp() {
  const totalSeats = 270; // Total number of seats to win
  const [leftVotePercentage, setLeftVotePercentage] = useState(50); // Kamala Harris's vote percentage
  const [rightVotePercentage, setRightVotePercentage] = useState(50); // Donald Trump's vote percentage

  // Function to calculate the projected seats for each candidate
  const calculateSeats = (percentage) => {
    const leftSeats = Math.round((percentage / 100) * totalSeats);
    const rightSeats = totalSeats - leftSeats; // Ensure total seats always add up to 270
    return { leftSeats, rightSeats };
  };

  // Function to handle changes in the slider
  const handleSliderChange = (event, newValue) => {
    setLeftVotePercentage(newValue);
    setRightVotePercentage(100 - newValue); // Automatically adjust the other candidate's vote
  };

  const { leftSeats, rightSeats } = calculateSeats(leftVotePercentage);

  // Function to generate a dynamic background color based on the left vote percentage
  const generateGradient = () => {
    // Left side is blue (Kamala Harris), right side is red (Donald Trump)
    return `linear-gradient(to right, rgb(66,82,168) ${leftVotePercentage}%, rgb(255, 61, 61) ${leftVotePercentage}%)`;
  };

  return (
    <>
      {/* Prediction Section */}
      <div className="flex flex-row justify-center gap-9 py-10 my-10">
        <img
          src={leftPredictionPerson}
          alt="Kamala Harris"
          className="w-24 h-24"
        />
        <span className="flex flex-col text-center justify-center">VS</span>
        <img
          src={rightPredictionPerson}
          alt="Donald Trump"
          className="w-24 h-24"
        />
      </div>

      {/* Slider Section */}
      <div className="flex justify-center">
        <Box
          sx={{
            width: 600,
            height: 20,
            position: "relative",
            borderRadius: "0",
            paddingLeft: "2px",
            paddingRight: "2px",
            background: generateGradient(), // Apply dynamic gradient fill
          }}
        >
          {/* Left Label (Kamala Harris) */}
          <div className="text-[#4252A8] text-3xl absolute left-0 bottom-8 ">
            {leftSeats}
          </div>

          {/* Right Label (Donald Trump) */}
          <div className=" text-3xl text-[#E60A00] absolute right-0 bottom-8">
            {rightSeats}{" "}
          </div>

          <Slider
            aria-label="Vote Slider"
            value={leftVotePercentage}
            onChange={handleSliderChange}
            valueLabelDisplay="off"
            step={1}
            min={0}
            max={100}
            sx={{
              "& .MuiSlider-thumb": {
                width: 16,
                height: 16,
                border: "1px solid #000",
                borderRadius: "50%",
              },
              "& .MuiSlider-track": {
                backgroundColor: "#fff",
              },
              "& .MuiSlider-rail": {
                backgroundColor: "#fff",
              },
            }}
          />

          {/* Kamala Harris Label */}
          <div className="absolute left-0 top-8 text-left">
            <div className="">Kamala Harris</div>
            <div className="text-gray-500 text-xs">
              {leftVotePercentage}% of Vote
            </div>
          </div>

          {/* "270 to win" Label */}
          <div className="absolute left-1/2 top-12 transform -translate-x-1/2 text-xs text-gray-500 whitespace-nowrap">
            270 to win
          </div>

          {/* Donald Trump Label */}
          <div className="absolute right-0 top-8 text-right">
            <div className="">Donald Trump</div>
            <div className="text-gray-500 text-xs">
              {rightVotePercentage}% of Vote
            </div>
          </div>
        </Box>
      </div>
    </>
  );
}

export default ElectionSeatPredictionComp;
