import { useEffect, useState } from "react";
import PreSectionHeader from "../../components/PreSectionHeader/PreSectionHeader";

import Personalfinance from "../../assets/icons/followyourinteresticons/Group 26684.svg";
import Artsanddesigns from "../../assets/icons/followyourinteresticons/Group 26685.svg";
import Politics from "../../assets/icons/followyourinteresticons/Group 26686.svg";
import Health from "../../assets/icons/followyourinteresticons/Group 26687.svg";
import Business from "../../assets/icons/followyourinteresticons/Group 26688.svg";
import Economy from "../../assets/icons/followyourinteresticons/Group 26689.svg";
import Finance from "../../assets/icons/followyourinteresticons/Group 26690.svg";
import Science from "../../assets/icons/followyourinteresticons/Group 26701.svg";
import Digitalcurrencies from "../../assets/icons/followyourinteresticons/Group 26692.svg";
import Sports from "../../assets/icons/followyourinteresticons/Group 26693.svg";
import Highereducation from "../../assets/icons/followyourinteresticons/Group 26694.svg";
import Entertainment from "../../assets/icons/followyourinteresticons/Group 26695.svg";
import Vehicles from "../../assets/icons/followyourinteresticons/Group 26696.svg";
import Travel from "../../assets/icons/followyourinteresticons/Group 26697.svg";
import Technology from "../../assets/icons/followyourinteresticons/Group 26698.svg";
import World from "../../assets/icons/followyourinteresticons/Group 26699.svg";
import Cricket from "../../assets/icons/followyourinteresticons/Group 26700.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getFollowYourInterest,
  postInterest,
} from "../../redux/actions/privateApiAction";
import { useNavigate } from "react-router-dom";
// import {  useNavigate } from "react-router-dom";

function FollowYourInterestsComp() {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  // const location = useLocation();


  const { followyourinterest,postInterestRes } = useSelector((state) => state.privateReducer);
  // const followingData = [
  //   { id: 1, Image: Personalfinance, data: "Personal finance" },
  //   { id: 2, Image: Politics, data: "Politics" },
  //   { id: 7, Image: Business, data: "Business" },
  //   { id: 3, Image: Finance, data: "Finance" },
  //   { id: 8, Image: Digitalcurrencies, data: "Digital currencies" },
  //   { id: 16, Image: Sports, data: "Sports" },
  //   { id: 9, Image: Highereducation, data: "Higher education" },
  //   { id: 10, Image: Entertainment, data: "Entertainment" },
  //   { id: 11, Image: Vehicles, data: "Vehicles" },
  //   { id: 12, Image: Travel, data: "Travel" },
  //   { id: 13, Image: Technology, data: "Technology" },
  //   { id: 14, Image: World, data: "World" },
  //   { id: 15, Image: Cricket, data: "Cricket" },
  //   { id: 17, Image: Science, data: "Science" },
  //   { id: 6, Image: Artsanddesigns, data: "Arts and designs" },
  //   { id: 4, Image: Economy, data: "Economy" },
  //   { id: 5, Image: Health, data: "Health" },
  // ];

  const [selectedIds, setSelectedIds] = useState([]);

  const handleClick = (id) => {
    setSelectedIds((prevSelectedIds) =>
      prevSelectedIds.includes(id)
        ? prevSelectedIds.filter((itemId) => itemId !== id)
        : [...prevSelectedIds, id]
    );
  };

  const isButtonActive = selectedIds.length >= 3;

  // console.log("followyourinterest", followyourinterest);

  const handleSubmit = () => {
    dispatch(postInterest(selectedIds));
   
  };
useEffect(()=>{
  if(postInterestRes){
    navigate("/")
  }
},[postInterestRes])
  useEffect(() => {
    dispatch(getFollowYourInterest());
  }, [dispatch]);

  // console.log("selectedIds", selectedIds);

  // useEffect(()=>{
  //   if(selectedIds.length>=3){
  //   dispatch(postInterest(selectedIds))
  // }
  // },[selectedIds])

  return (
    <>
      <div className="bg_home bg-[#F7F8FA]  md:px-40 flex flex-col md:pb-14">
        <div className="flex flex-row md:m-12 ">
          <div className="bg-[#F7F8FA] md:bg-white w-full rounded-xl shadow-sm p-4">
            <div className="text-center ">
           
              <div className="flex justify-between items-center">
                <div className="font-semibold text-[12px]">
                  <div className="text-[#1A1A1A] cursor-pointer  items-center gap-2 text-[18px]  font-medium opacity-90 hidden md:flex">
                  Follow your interests
                  </div>
                  <div className="md:hidden"> <PreSectionHeader title={"Follow your interests"} textbold="true" /></div>
                 
                </div>
              </div>
            </div>
            <hr className="border-b-1 md:border-none  my-4 md:my-0 font-bold"/>
            <div className="text-[12px] text-[#7F8FA4] pb-3 md:pb-0 md:my-2">
              Choose topics that'll appear in your For You news feed and under
              what you're Following
            </div>
            <div className="gap-2 py-2 mb-2 flex flex-wrap">
              {followyourinterest.map((item) => (
                <div
                  key={item.id}
                  className={`inline-flex items-center rounded-xl border cursor-pointer p-3 md:p-2  ${
                    selectedIds.includes(item.id) ? "border bg-[#E2E7EE]" : ""
                  }`}
                  onClick={() => {
                    handleClick(item?.id);
                    // console.log("item?.id", item?.id);
                  }}
                >
                  <div className="flex items-center bg-cover gap-2" style={{backgroundRepeat:"none"}}>
                    <img
                      src={item?.icon}
                      alt="Icon"
                      className="w-5 h-5"
                    />
                    <div className="text-[12px] opacity-90 font-semibold text-gray-700">
                      {item?.name}
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="pt-64">
              <div className="flex flex-col items-center">
                <div className="text-sm text-center mb-2">
                  {selectedIds.length} topics selected (min. 3 topics)
                </div>
                <button
                  // to={isButtonActive ? "/following" : "#"}
                  className={`rounded-lg  py-2 text-[14px] font-semibold px-24 bg-[#FF3D00] text-white ${
                    isButtonActive ? "" : "opacity-30 cursor-not-allowed"
                  }`}
                  aria-disabled={!isButtonActive}
                  onClick={handleSubmit}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FollowYourInterestsComp;
