import { Link, useLocation } from "react-router-dom";

function PrivacyPolicy() {
  const location = useLocation();
  window.scrollTo(0, 0);

  // console.log("location",location.pathname);

  const isVerified = localStorage.getItem("accessToken");
  const breadCumb = [
    { id: 1, name: isVerified ? "For You " : "News ", link: "/" },
    {
      id: 2,
      name: "Privacy Policy",
      link: `${location?.pathname}`,
    },
  ];
  return (
    <>
      <div className="bg_home md:bg-[#F7F8FA]  px-0 min-[840px]:px-40 flex flex-col min-[840px]:pb-14 md:py-0">
        <div className="flex flex-row items-center py-5">
          {breadCumb.map((item, index) => (
            // console.log("item",item),

            <span
              key={item.id}
              className={`text-[10px] font-medium  ${
                index === breadCumb.length - 1 ? "" : "opacity-60"
              }`}
            >
              {index > 0 && <span className="mx-1">&gt;</span>}
              <Link to={item?.link} className="breadcrumb-link">
                {item.name}
              </Link>
            </span>
          ))}
        </div>
        <div className="md:bg-[#FFFFFF] w-full  md:round-lg ">
          <div className="bg-white w-full  p-3 md:p-5 md:round-lg min-h-[10rem] font-sans">
            {/* <SecHead title={"Top Stories"} redirectLink="/top-stories" /> */}
            <h3 className=" text-[21px] leading-[20px] font-semibold tracking-normal text-[#1A1A1A] hidden md:block mb-3">
              Privacy Policy
            </h3>
            <div className=" my-3">
              <p>
                Your Privacy is important to us. As a company, we prefer knowing
                about you only as much as the local laws prescribe and nothing
                more. That said, our ad partners would perhaps want to know you
                adequately so that the targeted ads sustain businesses like ours
                while also respecting your privacy. This document details what
                information we and our partners collect and how it is used. In
                the following paragraphs, the company means Opinions and Ratings
                and You means any Reader on our Website(s). By using our
                websites, you are consenting to provide us with personal data as
                per the specific laws of your country.
              </p>
              <p className="my-3">This document does not cover</p>
              <ol className="px-5 my-2">
                <li className="list-disc ml-5">
                  {" "}
                  Information that you may have already given to ad partners
                  like Google
                </li>
                <li className="list-disc ml-5">
                  Information and actions you may take after clicking
                  Advertisements on our website
                </li>
              </ol>
              <p className="text-[21px] font-semibold my-2  ">
                1. Information About You
              </p>
              <ol className="px-5 my-2">
                <li className=" ml-5">
                  {" "}
                  <strong>a</strong>. We rarely collect personal information
                  about you unless you visit and purchase research from our
                  store. Since that involves a financial transaction, we will
                  need basic information about you that is evidence to tax
                  authorities that it is a legitimate transaction. We do collect
                  e-mail addresses but that gives us very little information
                  about you as a person.
                </li>
                <li className=" ml-5 my-1 ">
                  <strong>b</strong>. We allow our advertisers and analytics
                  partners (Eg: Google analytics) to collect certain information
                  when you visit our site by allowing them to place a tracking
                  mechanism, such as cookies or other technologies to collect
                  and use information about you during your visit to this and
                  other websites in order to provide advertisements they feel
                  you would like.
                </li>
              </ol>
              <p className="text-[21px] font-semibold my-2  ">
                2. How is the Information used?
              </p>
              <p className="my-3">
                To deliver relevant advertising to you and to measure and
                optimize the effectiveness of our advertising
              </p>
              <ol className="px-5 my-2">
                <li className="list-disc ml-5">
                  {" "}
                  The browser or device identifiers
                </li>
                <li className="list-disc ml-5">
                  Physical/geolocation or Internet Protocol (IP) address
                </li>

                <li className="list-disc ml-5">
                  Characteristics such as age and sex{" "}
                </li>
                <li className="list-disc ml-5">Education </li>
                <li className="list-disc ml-5">Personal interests</li>
                <li className="list-disc ml-5">
                  Inferences about consumer preferences and characteristics
                </li>
              </ol>
              <p className="text-[21px] font-semibold my-2  ">
                3. Can you opt out of the cookies?
              </p>
              <p className="my-3">
                To opt-out of the cookies you may visit 
                <a
                  className="font-bold text-blue-600 underline"
                  href="https://policies.google.com/technologies/ads"
                  target="blank"
                >
                  https://policies.google.com/technologies/ads
                </a>
                .
              </p>
              <p className="text-[21px] font-semibold my-2  ">
                4. Changes to this Privacy Statement
              </p>
              <p className="my-3">
                We may improve this Privacy statement over time. If you have any
                concerns, write to us.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default PrivacyPolicy;
