import PreSectionHeader from "../../components/PreSectionHeader/PreSectionHeader";
import cron from "../../assets/icons/Crown_White.svg";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { email_Regex, mobileNumber_Regex } from "../../components/globalRegex";
import { useDispatch, useSelector } from "react-redux";
import {
  accountUpdate,
  getAccountDetails,
} from "../../redux/actions/privateApiAction";
import ConfirmationModel from "../../components/CustomModel/ConfirmationModel";
import { BASE_URL_PP } from "../../redux/api";

function Myaccount() {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const [update, setupdate] = useState(false);
  const [isCheckValid, setIsCheckValid] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [inputData, setInputData] = useState({
    firstName: "",
    lastName: "",
    email: localStorage.getItem("email"),
    mobile: "",
    profilePicture: "",
  });

  window.scrollTo(0, 0);
  const isVerified = localStorage?.getItem("accessToken");
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAccountDetails());
    if (!isVerified) {
      navigate("/login");
    }
  }, [update]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { getaccount, confirmUpdateAccount } = useSelector(
    (state) => state.privateReducer
  );

  // console.log("getaccount ", getaccount);

  // Field validation function
  const checkValidation = (type) => {
    if (type === "firstName") {
      if (!inputData?.firstName) {
        return { isValid: false, message: "First Name is Required." };
      }
      if (inputData?.firstName && !/^[A-Za-z]+$/.test(inputData?.firstName)) {
        return { isValid: false, message: "Only characters allowed" };
      }
      if (inputData?.firstName?.length > 100) {
        return {
          isValid: false,
          message: "max length should not be greater than 100 char.",
        };
      }
      return { isValid: true, message: "" };
    }
    if (type === "lastName") {
      if (!inputData?.lastName) {
        return { isValid: false, message: "Last Name is Required." };
      }
      if (inputData?.lastName && !/^[A-Za-z]+$/.test(inputData?.lastName)) {
        return { isValid: false, message: "Only char value" };
      }
      if (inputData?.lastName?.length > 100) {
        return {
          isValid: false,
          message: "max length should not be greater than 100 char.",
        };
      }
      return { isValid: true, message: "" };
    }
    if (type === "email") {
      if (!inputData?.email) {
        return { isValid: false, message: "Email ID is Required." };
      }
      if (inputData?.email && !email_Regex.test(inputData?.email)) {
        return { isValid: false, message: "Invalid Email" };
      }
      return { isValid: true, message: "" };
    }
    if (type === "mobile") {
      if (!inputData?.mobile) {
        return { isValid: false, message: "Mobile Number is Required." };
      }
      if (inputData?.mobile?.length > 20 && inputData?.mobile) {
        return {
          isValid: false,
          message: "Maximum 20 Digit length allowed for Contact Number",
        };
      }
      if (!mobileNumber_Regex.test(inputData?.mobile) && inputData?.mobile) {
        return { isValid: false, message: "Invalid Mobile Number" };
      }
      return { isValid: true, message: "" };
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputData({
      ...inputData,
      [name]: value,
    });
  };

  const imgRef = useRef(null);

  const focusInput = () => {
    imgRef.current.click();
  };

  // const getInputValue = ()=>{
  //   alert(imgRef.current.value)
  // }

  const handleUpdate = async () => {
    // console.log("update function hitted");
    setIsCheckValid(true);
    if (
      checkValidation("firstName").isValid &&
      checkValidation("lastName").isValid &&
      checkValidation("email").isValid &&
      checkValidation("mobile").isValid
    ) {
      dispatch(accountUpdate(inputData));
      localStorage.setItem("acccountupdate", true);
      setIsModalOpen(true);
      setupdate(!update);

      setTimeout(() => {
        window.location.reload();
      }, 1500);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setupdate(!update);
    setIsEditing(false); // Optionally reset editing mode after closing the modal
  };

  const handleConfirmUpdate = () => {
    // Optionally handle confirmation logic here
    handleUpdate();
    setIsModalOpen(false);
    setIsEditing(false);
    // Navigate to another page if needed
    // navigate("/");
  };

  const handleEditToggle = () => {
    setIsEditing(true);
  };
  const handleConfirm = () => {
    setIsModalOpen(true);
  };
  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      let formData = new FormData();
      formData.append("image", file);
      // Log FormData contents
      try {
        const response = await fetch(`${BASE_URL_PP}common/image-upload`, {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          // setImage(data?.data);
          setInputData({
            ...inputData,
            profilePictureId: data?.data?.id,
            profilePicture: data?.data?.imageUrl,
          });
          // console.log("data ", data);
        } else {
          console.error("Upload failed with status:", response.status);
        }
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  useEffect(() => {
    if (getaccount) {
      setInputData({
        firstName: getaccount?.firstName || "",
        lastName: getaccount?.lastName || "",
        email: getaccount?.email || inputData?.email,
        mobile: getaccount?.mobile || "",
        profilePicture: getaccount?.profilePicture || "",
      });
    }
  }, [getaccount]);

  // console.log(image?.imageUrl);

  // useEffect(() => {
  //   if (confirmUpdateAccount) {
  //     setIsModalOpen(true);
  //   }
  // }, [confirmUpdateAccount]);

  return (
    <div className="bg_home flex flex-col pb-8 bg-white md:bg-[#F7F8FA]">
      <div className="bg_home px-4 md:px-40 flex flex-col pb-14">
        <div className="bg-white w-full rounded-xl shadow-sm p-3 md:p-5 mt-6">
          <PreSectionHeader title={"My Account"} />
          <div className="flex flex-col items-center justify-center p-4 lg:mx-32">
            <div
              className="bg-black rounded-full cursor-pointer"
              onClick={focusInput}
            >
              <img
                src={inputData?.profilePicture || cron}
                alt="Profile Icon"
                className="mx-auto p-4 rounded-full w-24 h-24"
              />
            </div>
            <input
              type="file"
              className="hidden"
              ref={imgRef}
              disabled={!isEditing}
              onChange={handleImageChange}
            />

            {/* {console.log("inputData?.profilePicture ", inputData)} */}

            {/* Form Start */}
            <form className="w-full mt-20 md:mt-6">
              <div className="grid gap-6 mb-6 md:grid-cols-2">
                <div className="flex flex-col">
                  <div className="input-container">
                    <input
                      type="text"
                      id="firstName"
                      name="firstName"
                      placeholder=" "
                      autoComplete="off"
                      className="bg-transparent focus:bg-transparent"
                      value={inputData?.firstName ?? ""}
                      onChange={handleChange}
                      disabled={!isEditing}
                    />
                    <label htmlFor="firstName">First Name</label>
                  </div>
                  {checkValidation("firstName") && isCheckValid && (
                    <span className="error-message">
                      {checkValidation("firstName").message}
                    </span>
                  )}
                </div>
                <div className="flex flex-col">
                  <div className="input-container">
                    <input
                      type="text"
                      id="lastName"
                      name="lastName"
                      placeholder=" "
                      autoComplete="off"
                      className="bg-transparent focus:bg-transparent"
                      value={inputData?.lastName ?? ""}
                      onChange={handleChange}
                      disabled={!isEditing}
                    />
                    <label htmlFor="lastName">Last Name</label>
                  </div>
                  {checkValidation("lastName") && isCheckValid && (
                    <span className="error-message">
                      {checkValidation("lastName").message}
                    </span>
                  )}
                </div>
                <div className="flex flex-col">
                  <div className="input-container">
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder=" "
                      autoComplete="off"
                      className="bg-transparent focus:bg-transparent"
                      value={inputData?.email ?? ""}
                      onChange={handleChange}
                      disabled
                    />
                    <label htmlFor="email">Email ID</label>
                  </div>
                  {checkValidation("email") && isCheckValid && (
                    <span className="error-message">
                      {checkValidation("email").message}
                    </span>
                  )}
                </div>
                <div className="flex flex-col">
                  <div className="input-container">
                    <input
                      type="number"
                      id="mobile"
                      name="mobile"
                      placeholder=" "
                      autoComplete="off"
                      className="bg-transparent focus:bg-transparent"
                      value={inputData?.mobile ?? ""}
                      onChange={handleChange}
                      disabled={!isEditing}
                    />
                    <label htmlFor="mobile">Mobile Number</label>
                  </div>
                  {checkValidation("mobile") && isCheckValid && (
                    <span className="error-message">
                      {checkValidation("mobile").message}
                    </span>
                  )}
                </div>
              </div>
            </form>

            <div className="flex flex-col mt-20">
              <button
                type="button"
                className={`w-full rounded-lg py-2 text-[14px] font-semibold text-nowrap ${
                  isEditing ? "bg-[#FF3D00]" : "bg-[#FF3D00] opacity-80 "
                } text-white text-center px-24 md:px-32`}
                onClick={isEditing ? handleConfirm : handleEditToggle}
              >
                {isEditing ? "Update" : "Edit Your Profile"}
              </button>
            </div>
            {/* Form End */}
          </div>
        </div>
      </div>
      {isModalOpen && (
        <ConfirmationModel
          title="Are you sure?"
          message="Do you really want to update your account information?"
          handleConfirm={handleConfirmUpdate}
          handleCloseModal={handleCloseModal}
          confirmBtn="Yes"
          // cancelBtn="No"
        />
      )}
    </div>
  );
}

export default Myaccount;
