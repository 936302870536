import { FaSearch } from "react-icons/fa";
import WithLayout from "../../components/Layout/Layout";
import TopStoriesHomeComp from "../../components/HomeComp/TopStoriesHomeComp";
import LatestPostHomeComp from "../../components/HomeComp/LatestPostHomeComp";
import LocalNewsHomeComp from "../../components/HomeComp/LocalNewsHomeComp";
import TrendingHomeComp from "../../components/HomeComp/TrendingHomeComp";
import PickForYouHomeComp from "../../components/HomeComp/PickForYouHomeComp";
import MorePostHomeComp from "../../components/HomeComp/MorePostHomeComp";
import YourTopicsHomeComp from "../../components/HomeComp/YourTopicsHomeComp";
import { useState } from "react";
import SearchModel from "../../components/CustomModel/SearchModel";
// import USMap from "../../components/mapComp/Map";

const Home = () => {
  const [isSeachModel, setSearchModel] = useState(false);

  const isVerified = localStorage.getItem("accessToken");
  const handleSearchModel = () => {
    setSearchModel(true);
  };
  return (
    <div className="bg_home bg-[#F7F8FA] px-4 min-[955px]:px-40 flex flex-col pb-5">
      <div className="bg-white rounded-[10px] flex items-center gap-2 px-4 py-2 hidden my-5">
        <FaSearch />
        <div className="h-[23px] w-[1px] bg-gray-300" />
        <input
          type="search"
          placeholder="Search Keyword"
          className="border-none outline-none text-xs bg-transparent rounded-md py-1"
          onClick={handleSearchModel}
        />
      </div>
      {/* <USMap /> */}
      <div className="flex-[2]">
        <div className="flex flex-col min-[1280px]:flex-row gap-5 py-5">
          <div className="flex-[2] flex flex-col gap-5">
            <TopStoriesHomeComp />
          </div>
          <div className="flex-1 flex flex-col gap-5">
            <LocalNewsHomeComp />
            <LatestPostHomeComp />
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full md:flex-row gap-5 py-[10px]">
        <div className=" w-full md:w-[55%]">
          <TrendingHomeComp />
        </div>
        <div className=" w-full  md:w-[45%]">
          <PickForYouHomeComp />
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-5 py-[10px]  ">
        <div className="flex-1">
          <MorePostHomeComp />
        </div>
      </div>

      {isVerified ? (
        <>
          {/* <div className="flex flex-col md:flex-row gap-5 py-[10px]">
        <div className="flex-1">
          <ForYouData />
        </div>
      </div> */}
          <div className="flex flex-col md:flex-row gap-5 py-[10px]">
            <div className="flex-1">
              <YourTopicsHomeComp />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}

      {/* seacrh model */}
      {isSeachModel && (
        <SearchModel
          title="Are you sure?"
          message="Do you really want to exit this."
          handleConfirm=""
          handleCloseModal=""
          confirmBtn="Leave"
          cancelBtn="Stay"
        />
      )}
    </div>
  );
};

Home.Layout = WithLayout;

export default Home;
